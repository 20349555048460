import axios from 'axios'
import { Communication } from '@bertazzoni/common/src/models/communication.model'
import { buildUrl, PaginationData } from '@bertazzoni/common/src/helpers/urlFactory'

const API_URL = process.env.VUE_APP_API_ENDPOINT + '/communications'

class ContentService {
  async findAllSent(): Promise<Communication[]> {
    const response = await axios.get(`${API_URL}/findAll/emailSent`)
    return response.data
  }

  async findAllReceived(): Promise<Communication[]> {
    const response = await axios.get(`${API_URL}/findAll/received`)
    return response.data
  }

  async ExportEmails(recipients: string[]): Promise<string> {
    const url = `${API_URL}/export/emails?recipientsEntities=${recipients[0]}`
    if (recipients.length > 1) {
      recipients.map((entity: string) => {
        //url += `&recipientsEntities=${entity}`
      })
    }
    const response = await axios.post(url, {
      headers: {
        'Content-Type': 'text/plain'
      }
    })

    return response.data
  }
  async sendANewOne(com: Communication): Promise<void> {
    const url = `${API_URL}/sendANewOne/to?subject=${com.title}&recipientsEntities=${com.recipients}`
    if (com.recipients.length > 1) {
      com.recipients.map((entity: string) => {
        //url += `&recipientsEntities=${entity}`
      })
    }
    return await axios.post(url, { contentHtml: com.description })
  }
}

export default new ContentService()
