


















import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import CommunicationsService from '@bertazzoni/common/src/services/CommunicationsService'
import { Communication } from '@bertazzoni/common/src/models/communication.model'
import { TableInfo } from '@bertazzoni/back/models/table.model'
import { isErrorNotFound } from '@bertazzoni/common/models/user.error'

@Component({
  components: {
    Loader,
    PaginatedTable
  }
})
export default class ComList extends Vue {
  private pageLoaded = false
  private comList: Communication[] = []
  private tableInfoSent: TableInfo = new TableInfo()

  async created(): Promise<void> {
    this.setTableInfo()
    try {
      this.comList = await CommunicationsService.findAllSent()
    } catch (error) {
      if (isErrorNotFound(error)) {
        this.comList = []
      } else {
        throw error
      }
    }
    this.pageLoaded = true
  }
  setTableInfo(): void {
    this.tableInfoSent.getCustomTable().displayButton = false
    this.tableInfoSent.getCustomTable().isPaginated = false
  }
}
